// FloatingToc.tsx
import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'gatsby';

interface Heading {
    id: string;
    value: string;
    depth: number;
}

interface FloatingTocProps {
    headings: Heading[];
}

const FloatingToc: React.FC<FloatingTocProps> = ({ headings }) => {
    const [activeId, setActiveId] = useState('');
    const tocRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setActiveId(entry.target.id);
                    }
                });
            },
            { rootMargin: '0% 0% -50% 0%' } // ここを調整
        );

        headings.forEach((heading) => {
            const element = document.getElementById(heading.id);
            if (element) {
                observer.observe(element);
            }
        });

        return () => {
            headings.forEach((heading) => {
                const element = document.getElementById(heading.id);
                if (element) {
                    observer.unobserve(element);
                }
            });
        };
    }, [headings]);

    useEffect(() => {
        const tocElement = tocRef.current;
        if (tocElement) {
            const activeElement = tocElement.querySelector('.active');
            if (activeElement) {
                const activeElementRect = activeElement.getBoundingClientRect();
                const tocRect = tocElement.getBoundingClientRect();
                const scrollOffset = activeElementRect.top - tocRect.top - (tocElement.clientHeight / 2) + (activeElement.clientHeight / 2);
                tocElement.scrollTop = Math.max(0, tocElement.scrollTop + scrollOffset);
            }
        }
    }, [activeId]);

    return (
        <div className="floating-toc" ref={tocRef}>
            <ul>
                {headings.map((heading) => (
                    <li key={heading.id} className={activeId === heading.id ? 'active' : ''}>
                        <Link to={`#${heading.id}`}>{heading.value.replace(/<code[^>]*>|<\/code>/g, '')}</Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default FloatingToc;