import React from 'react';
import { connectSearchBox, ConnectedSearchBoxProvided, SearchBoxExposed } from 'react-instantsearch-dom';
import { Search as SearchIcon } from '@styled-icons/fa-solid/Search';

// 追加のプロパティの型を定義
interface CustomSearchBoxProps extends ConnectedSearchBoxProvided {
    className?: string;
    onFocus?: () => void;
}

const CustomSearchBox: React.FC<CustomSearchBoxProps> = ({
    refine,
    currentRefinement,
    className,
    onFocus
}) => (
    <form className={`search-form ${className}`} onSubmit={(e) => e.preventDefault()}>
        <input
            className="search-input"
            type="text"
            placeholder="Search" // ここでプレースホルダーを追加
            aria-label="Search"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => refine(e.target.value)}
            value={currentRefinement}
            onFocus={onFocus}
        />
        <button
            type="button"
            className="search-button"
            onClick={() => refine(currentRefinement)}
        >
            <SearchIcon className="search-icon" />
        </button>
    </form>
);

// 型キャストを使って正しい型を渡す
const ConnectedCustomSearchBox = connectSearchBox<CustomSearchBoxProps, SearchBoxExposed>(CustomSearchBox);

export default ConnectedCustomSearchBox;