/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'gatsby';
import {
    connectStateResults,
    Highlight,
    Hits,
    Index,
    Snippet,
    PoweredBy,
} from 'react-instantsearch-dom';

const HitCount = connectStateResults(({ searchResults }) => {
    const hitCount = searchResults && searchResults.nbHits;

    return hitCount > 0 ? (
        <div className="HitCount">
            {hitCount} result{hitCount !== 1 ? `s` : ``}
        </div>
    ) : null;
});

import { useStaticQuery, graphql } from 'gatsby';

const PageHit = ({ hit }) => {
    // サイトのベースURLを取得
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    url
                }
            }
        }
    `);

    const baseUrl = data.site.siteMetadata.url;

    return (
        <div>
            <Link to={`${baseUrl}${hit.slug}`}>
                <h4>
                    <Highlight attribute="title" hit={hit} tagName="mark" />
                </h4>
            </Link>
            <Snippet attribute="excerpt" hit={hit} tagName="mark" />
        </div>
    );
};

const HitsInIndex = ({ index }) => {
    console.log('HitsInIndex index:', index);  // デバッグログ
    return (
        <Index indexName={index.name}>
            <HitCount />
            <Hits className="Hits" hitComponent={PageHit} />
        </Index>
    );
};

const SearchResult = ({ indices, className }) => {
    console.log('SearchResult indices:', indices);  // デバッグログ
    return (
        <div className={className}>
            {indices.map((index) => (
                <HitsInIndex index={index} key={index.name} />
            ))}
            <PoweredBy />
        </div>
    );
};

export default SearchResult;