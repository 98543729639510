import React, { useRef, useState } from 'react';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-dom';
import { ThemeProvider } from 'styled-components';
import StyledSearchBox from './StyledSearchBox';
import StyledSearchResult from './StyledSearchResult';
import StyledSearchRoot from './StyledSearchRoot';
import useClickOutside from './useClickOutside';

const theme = {
    foreground: '#050505',
    background: 'white',
    faded: '#888',
};

// インデックスの型を定義
type Index = {
    name: string;
};

// コンポーネントのプロパティの型を定義
interface SearchProps {
    indices: Index[];
}

// 環境変数のデフォルト値を設定
const ALGOLIA_APP_ID = process.env.REACT_APP_ALGOLIA_APP_ID || 'WJJ7R3JPI0';
const ALGOLIA_SEARCH_KEY = process.env.REACT_APP_ALGOLIA_SEARCH_KEY || '674e1a378bc66a1c550c739d04176dbf';
const ALGOLIA_INDEX_NAME = process.env.REACT_APP_ALGOLIA_INDEX_NAME || 'index_240617';

// デバッグのためのログ
console.log('REACT_APP_ALGOLIA_APP_ID:', ALGOLIA_APP_ID);
console.log('REACT_APP_ALGOLIA_SEARCH_KEY:', ALGOLIA_SEARCH_KEY);
console.log('REACT_APP_ALGOLIA_INDEX_NAME:', ALGOLIA_INDEX_NAME);

const Search: React.FC<SearchProps> = ({ indices }) => {
    console.log('Indices:', indices);  // インデックス名を確認
    const rootRef = useRef<HTMLDivElement>(null);
    const [query, setQuery] = useState<string>('');
    const [hasFocus, setFocus] = useState<boolean>(false);
    const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY);

    useClickOutside(rootRef, () => setFocus(false));

    return (
        <ThemeProvider theme={theme}>
            <StyledSearchRoot ref={rootRef}>
                <InstantSearch
                    searchClient={searchClient}
                    indexName={indices[0].name}
                    onSearchStateChange={({ query: searchQuery }) => setQuery(searchQuery || '')}
                >
                    <StyledSearchBox onFocus={() => setFocus(true)} hasFocus={hasFocus} />
                    <StyledSearchResult show={query.length > 0 && hasFocus} indices={indices} />
                </InstantSearch>
            </StyledSearchRoot>
        </ThemeProvider>
    );
};

// デフォルトのインデックスを設定して、Search コンポーネントをエクスポート
export default () => (
    <Search indices={[{ name: ALGOLIA_INDEX_NAME }]} />
);